<template>
  <div id="videoDetail">
    <video width="100%" ref="videoView" :src="data.videoUrl" controls></video>
    <div class="conetnt">
      <div class="title">
        <div class="tip">{{ data.videoName }}</div>
        <div class="tag">{{ data.tagName }}</div>
      </div>
      <div class="equipment">器材：{{ data.aidNames }}</div>
      <div class="practice">
        <div class="way">● 练习方法</div>
        <div v-html="data.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      data: {},
    };
  },
  computed: {},
  created() {
    this.data = JSON.parse(window.sessionStorage.getItem('videoInfo'));
    console.log(this.data);
  },
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style lang="scss" scoped>
#videoDetail {
  font-size: 15px;
  .conetnt {
    padding: 10px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      color: #333333;
      font-weight: 700;
      font-size: 15px;
      vertical-align: middle;
      .tip {
        position: relative;
        padding-left: 10px;
        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 4px;
          height: 100%;
          background: #1989fa;
        }
      }
      .tag {
        color: #1989fa;
      }
    }
    .equipment {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #1989fa;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    .way {
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
